import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { colors, margins, mobileThresholdPixels } from '../styledComponents';
import Header from '../../components/partner/Header';
import PartnersPager from '../../components/partner/PartnersPager';
import Dots from './v2/Dots';
import PartnerBlock1 from '../../components/partner/PartnerBlock1';
import PartnerBlock2 from '../../components/partner/PartnerBlock2';
import PartnerBlock3 from '../../components/partner/PartnerBlock3';
import PartnerBlock4 from '../../components/partner/PartnerBlock4';

const Container = styled.div`
  position: relative;
  background-color: ${colors.white};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 5px;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: -${margins.m};
  }
`;

const YourPartner = ({ openModal }) => (
  <Container>
    <Header />
    <PartnersPager />
    <Row><Dots big /></Row>
    <PartnerBlock1 openModal={openModal} />

    <Row><Dots big /></Row>
    <PartnerBlock2 openModal={openModal} />

    <Row><Dots big /></Row>
    <PartnerBlock3 openModal={openModal} />

    <Row><Dots big /></Row>
    <PartnerBlock4 />
  </Container>
);

YourPartner.propTypes = {
  openModal: PropTypes.func.isRequired,
};

export default YourPartner;
